import React from "react";
import checkImage from "../../assets/home-page-image/check.svg";
import { Link } from "gatsby";

const Card = ({ title, descripition, docs, btnText, link }) => {
  return (
    <div
      className="flex items-center justify-around text-black flex-col bg-[#FFF] p-[30px] 
       
       md:w-[456px] w-[300px] md:h-[731px] rounded-[20px]  shadow-lg"
    >
      <p className="text-[#000] md:text-[35px] text-[25px] font-primary">
        {title}
      </p>
      <p className="text-[#37495B] md:text-[16px] text-[11px] font-opensans mt-[10px]">
        {descripition}
      </p>

      <div
        className="mt-[25px] grid grid-cols-2 gap-[15px] w-full  md:text-[16px] 
            text-[11px]"
      >
        {docs.map((item, index) => {
          return (
            <div key={index} className="flex gap-2  items-center">
              <img src={checkImage} alt="" className="md:w-[20px] w-[15px]" />
              <span>{item}</span>
            </div>
          );
        })}
      </div>

      <Link
        href={link}
        className="mt-[1rem] bg-[#006CFF] hover:opacity-80 rounded-full flex items-center py-[6px] px-4 text-white 
            md:h-[52px] lg:text-[16px] text-[11px]"
      >
        {btnText}
      </Link>
    </div>
  );
};

export default Card;
