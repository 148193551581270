import AcceptemceImage from "../../assets/home-page-image/acceptance.svg";
import IsoImage from "../../assets/home-page-image/iso.svg";
import OnlineImage from "../../assets/home-page-image/online.svg";
import SecureImage from "../../assets/home-page-image/secure.svg";

export const services = [
  {
    img: AcceptemceImage,
    title: "100% USCIS Acceptance",
  },
  {
    img: IsoImage,
    title: "ISO-certified",
  },
  {
    img: OnlineImage,
    title: "Order Online 24/7",
  },
  {
    img: SecureImage,
    title: "Secure & Confidential",
  },
];
