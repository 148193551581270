import React from "react";

const Step = ({ title, text, image }) => {
  return (
    <div
      className="flex steps md:gap-[75px] gap-[30px] md:mt-[80px] mt-[30px] xl:px-0 lg:px-4 
        md:px-0 px-0  "
    >
      <div className="flex flex-col justify-center gap-[25px] flex-1 pt-[35px] font-primary">
        <span className="text-[#006BFF] text-[18px] ">{title}</span>
        <p className="text-[#0F0F0F] lg:text-[40px] text-[35px] font-[600] ">
          {" "}
          {text}
        </p>
      </div>
      <div className="lg:flex-1  md:min-w-[260px] flex-1">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default Step;
