import React from "react";
import Card from "./ServiceCard";
import uploadImage from "../../../assets/home-page-image/upload.svg";

import talkImage from "../../../assets/home-page-image/talk.svg";
const NotSure = () => {
  return (
    <div className=" md:mt-[100px] mt-[50px] notSureWrapper mx-auto gap-[50px]  xl:px-0 lg:px-4">
      <Card
        logo={uploadImage}
        customClass="leftCard"
        link="/quote"
        text="In a rush? upload your documents now"
      />
      <Card
        logo={talkImage}
        customClass="rightCard"
        link="/contact-us"
        text="Complex Demands? speak to our manager"
      />
    </div>
  );
};

export default NotSure;
