import { Link } from "gatsby";
import React from "react";

const Card = ({ logo, text, customClass, link }) => {
  return (
    <div
      className={` flex-1 py-[125px] flex gap-[30px]  md:gap-[15px] lg:gap-[30px] justify-center items-center rounded-[8px] ${customClass}`}
    >
      <Link to={link}>
        <img
          src={logo}
          alt="logo"
          className="hover:scale-110  hover:ease-in-out duration-300 cursor-pointer"
        />
      </Link>

      <span
        className={` lg:max-w-[280px] max-w-[160px] lg:text-[24px] text-[14px] font-primary font-[700]`}
      >
        {text}
      </span>
    </div>
  );
};

export default Card;
