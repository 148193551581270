import React from "react";
import Banner from "./Banner";
import CertificationSamples from "../../components/CertificationSamples";
import MainUsp from "./MainUsp";
import CtaWithForm from "./CtaWithForm";
import SupportedLanguages from "../../components/SupportedLanguages";
import {
  certificationSampleData,
  certificationSampleTwoData,
  Uspdata,
  ctaWithFormData,
  pillarPageSupportedLanguagesData,
  supportedLanguagesData,
  bannerDataNew,
} from "../utils/sample-data";

import HeroSection from "LegalTranscription/Home-v2/banner";
import Services from "LegalTranscription/Home-v2/services";
import Location from "LegalTranscription/Home-v2/location";
import OurServices from "LegalTranscription/Home-v2/OurServices";
import Step from "LegalTranscription/Home-v2/Steps";
import step1image from "../../assets/home-page-image/step1.png";
import step2image from "../../assets/home-page-image/step2.svg";
import step3image from "../../assets/home-page-image/step3.svg";
import Document from "LegalTranscription/Home-v2/document";
import ServiceLoaction from "LegalTranscription/Home-v2/ServiceLocation";
import NotSure from "LegalTranscription/Home-v2/notSure";

function LegalTranscription() {
  return (
    // <>
    //   <Banner data={bannerDataNew} background="bg-midSlate" />
    //   <CertificationSamples
    //     data={certificationSampleData}
    //     background="bg-midSlate"
    //     className="pt-8 max-w-6xl mx-auto border-4 border-b-0 border-black rounded-30px rounded-b-none px-5"
    //     titleClassName="font-bold text-4xl md:text-5xl leading-[65px] text-blue font-primary xs:text-center sm:text-left"
    //   />
    //   <MainUsp data={Uspdata} background="bg-[#FFE8B8]" />
    //   <SupportedLanguages
    //     data={pillarPageSupportedLanguagesData}
    //     background="slateLinear"
    //   />
    //   <SupportedLanguages data={supportedLanguagesData} background="bg-white" />
    //   <CertificationSamples
    //     data={certificationSampleTwoData}
    //     background="bg-[#FFE8B8]"
    //     titleClassName="font-bold text-5xl leading-[65px] text-blue font-primary xs:text-center sm:text-left"
    //   />
    //   <CtaWithForm
    //     data={ctaWithFormData}
    //     background="bg-[#5927B0]"
    //     btnStyle="bg-black hover:bg-black"
    //   />
    // </>

    <>
      <section
        className="py-[9rem] text-black lg:max-w-[1240px] 
      lg:px-0 md:px-[4rem]  mx-auto"
      >
        <HeroSection />
        <Services />
        <Location />
        <OurServices />
        <div className="mt-[115px] font-primary flex flex-col gap-[25px] justify-center items-center  lg:w-[871px] mx-auto  md:px-[4rem]  px-4  lg:px-4">
          <span className="text-[#006BFF] text-[18px] font-[500]">
            HOW WE DO IT
          </span>
          <p className="text-[#000] lg:text-[40px] text-[35px] text-center font-[600] ">
            Translate Your Certificate or Document in Three Simple Steps
          </p>
        </div>
        <Step title="STEP ONE" text="Send your files" image={step1image} />
        <Step title="STEP TWO" text="Pay online" image={step2image} />
        <Step
          title="STEP THREE"
          text="Receive the translation"
          image={step3image}
        />
      </section>
      <div className="lg:max-w-[1240px] mx-auto">
        <Document />
      </div>

      <div className=" pb-[5rem] lg:px-0 lg:max-w-[1240px] md:px-[60px] mx-auto">
        <ServiceLoaction />
        <NotSure />
      </div>
    </>
  );
}
export default LegalTranscription;
