import React from "react";
import Users from "../../assets/home-page-image/Translators.png";
const ServiceLoaction = () => {
  return (
    <div className=" lg:px-10 mt-[100px]  items-center featureWrapper">
      <div className="flex-1">
        <h1 className="text-[#000] text-start font-[600] font-primary text-[40px]">
          Our Online Translation Company Brings Service To You - Anywhere
        </h1>
        <p className="text-[#000]  text-start  leading-[32px] mt-[25px] text-[16px] font-opensans lg:pr-[90px]">
          We take great pride in bringing our translation services to you—
          anywhere in the world. Our professional translators are online 24/7
          and ready to help you. So whether you’re in the United States or
          Japan, you can easily connect with our simple yet effective
          translation service.
        </p>
      </div>
      <div className="flex-1  md:mt-0 mt-5">
        <img src={Users} alt="" />
      </div>
    </div>
  );
};

export default ServiceLoaction;
