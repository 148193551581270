import React from "react";
import { specificationsOne, specificationsTwo } from "../utils/documentData";
import Card from "./Card";
const Document = () => {
  return (
    <div className=" md:px-[4rem] lg:mt-[150px] mt-[30px] py-[50px] px-4 lg:px-6 documentSection">
      <div className="font-primary flex flex-col gap-[25px] justify-center items-center  lg:w-[871px] mx-auto  md:px-[4rem]  px-4  lg:px-4">
        <span className="text-[#006BFF] text-[18px] font-[500]">
          WHY CHOOSE US
        </span>
        <p className="text-[#000] lg:text-[40px] text-[35px] text-center font-[600] ">
          Whether it’s a certificate or a document to your desired language, we
          got you.
        </p>
      </div>

      <div
        className="mt-[50px] flex lg:items-start items-center 
            md:px-[80px] gap-[100px] mx-auto justify-center cardWrapper"
      >
        <Card
          title="Translate Any Certificate or Document"
          descripition="Our online translation service makes your life easier with a straightforward process that’s easy to navigate. All your documents are easily uploaded and translated in a few simple steps."
          docs={specificationsOne}
          btnText=" SEE ALL DOCUMENTS"
          link="/documents"
        />
        <Card
          title="Choose Any Language & Open a World of Possibilities"
          descripition="After uploading your documents, just choose the language for the translation. You can access over 120 languages and 950+ language pairings from popular to lesser-known languages."
          docs={specificationsTwo}
          btnText=" SEE ALL LANGUAGES"
          link="/languages"
        />
      </div>
      <div></div>
    </div>
  );
};

export default Document;
