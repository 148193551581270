import React from "react";
import { Link } from "gatsby";
import EmailLogo from "../../assets/emailLogo.svg";

const HeroSection = () => {
  return (
    <>
      <div className="hero-section md:px-[4rem] px-4 flex flex-col items-center   ">
        <h1 className="text-[#0A1612] font-primary  text-center font-[700]  lg:max-w-[70%] mx-auto text-[40px] md:text-[45px] ">
          Online Translation Services for Official{" "}
          <span className="text-[#006BFF]"> Certificates</span> &{" "}
          <span className="text-[#006BFF]">Documents</span>
        </h1>
        <p className="text-[18px] mt-[10px] text-center font-primary text-[#37495B] leading[27px]  max-w-[1083px] md:px-0 p-1  mx-auto">
          Document translation doesn&apos;t have to be complicated. Get online
          translation for your certificates and documents when you need them
          most. With over 120 languages available, our professional translators
          provide certified translations for official use in the US and
          worldwide. Receive fast delivery and 24/7 support on your terms.
        </p>

        <div className="flex relative ">
          <img
            src={EmailLogo}
            alt="email icon"
            className="absolute  top-[30px] right-[180px]"
          />
          <Link
            to="/quote/"
            className="bg-[#006BFF] mt-[25px] flex items-center justify-center w-[177px]   text-[#FFF]  py-[14px] rounded-full   cursor-pointer"
          >
            LET’S GO
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
