import React from "react";
import { services } from "../utils/serviceData";

const Services = () => {
  return (
    <div className="md:px-[4rem] px-4 flex justify-between flex-wrap md:gap-0 gap-[2rem] mt-[50px]">
      {services?.map((item, index) => {
        return (
          <div key={index} className="flex items-center gap-[17px]">
            <img
              src={item.img}
              alt=""
              className="hover:scale-110  hover:ease-in-out duration-300 cursor-pointer"
            />
            <span className="w-[90px] text-black">{item.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Services;
