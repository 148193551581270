import React from "react";
import LegalTranscription from "../LegalTranscription/Home";
import Seo from "../components/seo";
import Layout from "../components/Layout";

function Index() {
  return (
    <Layout>
      <Seo
        title="Online Translation Services: Translate Certificates and Documents 24/7"
        description="Need online translation services? We offer certified translation for all your legal documents, birth certificates, academic papers, & more. Connect now."
      />
      <LegalTranscription />
    </Layout>
  );
}
export default Index;
