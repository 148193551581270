export const specificationsOne = [
  "Marriagecertificate",
  "Birth certificate",
  "Death certificate",
  "Visa",
  "Business license",
  "Driver's license",
  "Vaccine certificate",
  "Diploma",
  " Divorce decree/divorce certificate",
];

export const specificationsTwo = [
  "Spanish",
  "French",
  "Korean",
  "Japanese",
  "Chinese",
  "Russian",
  "Arabic",
  "Portuguese",
  "German",
  "Dutch",
  "Italian",
  "Polish",
];
