import React from "react";
import LeftBanner from "../../assets/home-page-image/leftBanner.svg";

const OurServices = () => {
  return (
    <div className="flex gap-[75px] w-full  xl:px-0 lg:px-4 md:px-0 ourServicesWrapper">
      <div className="flex flex-col lg:w-[50%] w-full gap-[25px] flex-1 pt-[35px] font-primary ">
        <p className="text-[#006BFF] text-[18px]  ">WHAT WE DO</p>
        <p className="text-[#0F0F0F] md:text-[40px]  text-[33px] font-[600] ">
          {" "}
          <span className="text-[#006CFF]">Reliable</span> Online Translation To
          Satisfy All Your Formal Requirements{" "}
        </p>
        <p className="text-[#000] text-[18px] font-opensans ">
          Our team strives to provide the fastest certified translation for
          formal procedures without sacrificing our signature high quality. We
          achieve this by working closely with you to understand your needs. So,
          whether you’re a private individual or part of a large business, we
          deliver on time and according to your most rigorous requirements.
        </p>
      </div>
      <div className="flex-1">
        <img src={LeftBanner} alt="" />
      </div>
    </div>
  );
};

export default OurServices;
