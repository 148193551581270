import React from "react";
import countryImage from "../../assets/home-page-image/countryLocation.svg";
const Location = () => {
  return (
    <div className="locationSection mt-[72px] md:px-[1rem] lg:px-0 ">
      <img src={countryImage} alt="" />
    </div>
  );
};

export default Location;
